var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tableData.sheetName
        ? _c("BlockTitle", {
            staticClass: "msg-title",
            attrs: { title: _vm.tableData.sheetName, "is-bold": true },
          })
        : _vm._e(),
      _c("a-table", {
        attrs: {
          "row-key": function (row) {
            return row.id + ""
          },
          columns: _vm.columns,
          "data-source": _vm.interception,
          scroll: _vm.scroll,
          pagination: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }